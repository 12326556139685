import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import ReactPaginate from "react-js-pagination";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getTodayPayments } from "src/api/customerApis";
import { formatDate } from "src/services/helpers";
import Swal from "sweetalert2";
import authInstance from "src/api/apiConfig";
import { successAlert } from "src/utils/Swal";
import { COMPANY_NAME } from "src/services/const";

const PaymentCollection = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { emiStatusData } = useSelector((state) => state?.emiReducer);
  const SchemaName = sessionStorage.getItem("schemaName");
  const [minEndDate, setMinEndDate] = useState("");
  const [status, setStatus] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [emiStatus, setEmiStatus] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [printState, setPrintState] = useState(false);
  const [selectedEmiStatus, setSelectedEmiStatus] = useState("");
  const [rows, setRows] = useState([]);
  const [org, setOrg] = useState([]);
  const [allSelectedData, setAllSelectedData] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const BRANCH_ID = sessionStorage.getItem("branc");
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const rowsPerPage = 10;

  const objData = {
    schemaName: SchemaName,
    emiStatus: "",
    startDate: startDate,
    enddate: endDate,
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const componentPDF = useRef();

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const handleSearch = () => {
    setStatus(true);
  };

  const fetchRecords = async (payload) => {
    try {
      const res = await getTodayPayments(payload);
      const filteredData =
        BRANCH_ID === "null"
          ? res?.data
          : res?.data.filter((item) => {
            return item?.branchId?.toString() === BRANCH_ID;
          });

      // Sort by time (descending)
      const sortedData = filteredData?.sort((a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      setRows(sortedData || []);
      setOrg(sortedData || []);
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    if (status === true) {
      fetchRecords(objData);
      setStatus(false);
      setShowTable(true);
    }
  }, [status, showTable]);

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setStartDate(selectedStartDate);
    setMinEndDate(selectedStartDate);
    if (endDate < selectedStartDate) {
      setEndDate("");
    }
  };

  const download_btn = () => {
    setPrintState(true);
  };

  const handleSubmitEditForm = async () => {
    const payload = {
      status: emiStatus,
      schemaName: SchemaName,
    };
    const response = await authInstance.patch(
      `payment/${allSelectedData?.id}`,
      payload
    );
    if (response.status === 201) {
      fetchRecords(objData);

      successAlert("date successfully updated");
      setOpenModel(false);
    }
  };

  const verifyData = (company) => {
    Swal.fire({
      title: "Are you sure you want to verify this payment?",
      showCancelButton: true,
      confirmButtonText: "Verify",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const payload = {
          status: "paid",
          schemaName: SchemaName,
        };
        const response = await authInstance.patch(
          `payment/${company?.id}`,
          payload
        );
        if (response.status === 201) {
          fetchRecords(objData);
          successAlert(`status successfully updated`);
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filterRows = () => {
    if (Array.isArray(org)) {
      const filteredData = org.filter((payment) => {
        const aadharNumber = payment?.customer?.aadharNumber
          ?.toString()
          .toLowerCase();
        const mobile = payment?.customer?.phoneNumber.toString().toLowerCase();
        const loanAccountNo =
          payment?.customer?.loanAccountNumber?.toLowerCase();
        const branchCode = payment.branch?.branchCode.toLowerCase();
        const branchName = payment.branch?.branchName.toLowerCase();
        const searchTerm = searchInput?.toLowerCase();

        if (!searchTerm) {
          return true;
        }

        if (!isNaN(searchTerm)) {
          return (
            aadharNumber.includes(searchTerm) || mobile.includes(searchTerm)
          );
        }
        if (searchTerm.trim().split(" ").length > 1) {
          return (
            loanAccountNo?.includes(searchTerm) ||
            branchName.includes(searchTerm) ||
            branchCode.includes(searchTerm)
          );
        }
        return (
          loanAccountNo?.includes(searchTerm) ||
          branchName.includes(searchTerm) ||
          branchCode.includes(searchTerm)
        );
      });
      setRows(filteredData);
    } else {
      setRows([]);
    }
  };

  useEffect(() => {
    filterRows();
    return () => {
      setRows([]);
    };
  }, [org, searchInput]);
  const filteredRows = selectedEmiStatus
    ? rows.filter((row) => row.status === selectedEmiStatus)
    : rows;

  return (
    <div className="content">
      <CModal
        scrollable
        visible={openModel}
        onClose={() => setOpenModel(false)}
        aria-labelledby="ScrollingLongContentExampleLabel2"
      >
        <CModalHeader>
          <CModalTitle id="ScrollingLongContentExampleLabel2">{`${allSelectedData?.customer?.firstName}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs={10}>
              <CFormInput
                type={"text"}
                value={emiStatus}
                placeholder={`${allSelectedData?.status}`}
                onChange={(e) => setEmiStatus(e.target.value)}
              />
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton onClick={handleSubmitEditForm} color="primary">
            Payment
          </CButton>
        </CModalFooter>
      </CModal>

      <CCard>
        <CCardHeader>
          <strong>All EMI Record</strong>
        </CCardHeader>
        <CCardBody>
          <div>
            <CRow className="mb-2">
              <CCol xs={6}>
                <CFormLabel htmlFor="address">
                  Start Date
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="date"
                  size="sm"
                  placeholder="Start Date"
                  aria-label="sm input example"
                  onChange={handleStartDateChange}
                  value={startDate}
                  required
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="address">
                  End Date
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="date"
                  size="sm"
                  placeholder="End Date"
                  aria-label="sm input example"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                  min={minEndDate}
                  required
                />
              </CCol>
            </CRow>
            <CRow>
              <div className="branch_btn">
                <CButton onClick={handleSearch}>Search</CButton>
              </div>
            </CRow>
          </div>
          {showTable && (
            <div>
              <div className="searchinput">
                <CRow>
                  <CCol xs={12} md={4} lg={5}>
                    <CFormLabel htmlFor="groupName">
                      MobileNumber/AadharNumber
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      size="sm"
                      placeholder="Enter desired value for searching"
                      aria-label="sm input example"
                      style={{ margin: "0px 0px 16px 0px" }}
                      onChange={handleSearchChange}
                      value={searchInput}
                    />
                  </CCol>
                  <CCol xs={12} md={4} lg={5}>
                    <div className="mb-4">
                      <CFormLabel htmlFor="address">
                        Select EMI Type
                        <span className="red-star">{` `}*</span>
                      </CFormLabel>
                      <CFormSelect
                        id=""
                        value={selectedEmiStatus}
                        onChange={(e) => setSelectedEmiStatus(e.target.value)}
                        required
                      >
                        <option value="">Select EMI Type</option>
                        <option value="paid">Paid</option>
                        <option value="pending">Pending</option>
                      </CFormSelect>
                    </div>
                  </CCol>

                  <CCol xs={12} md={4} lg={2}>
                    <Button
                      className="add-btn mr-10 w-auto"
                      onClick={download_btn}
                    >
                      Download
                    </Button>
                  </CCol>
                </CRow>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  <div>
                    Total paymentAmount :
                    <span style={{ color: "blue" }}>
                      {filteredRows?.reduce(
                        (total, row) => total + parseFloat(row.paymentAmount),
                        0
                      )}
                    </span>{" "}
                  </div>
                  <div>
                    Total penaltyAmount :{" "}
                    <span style={{ color: "blue" }}>
                      {filteredRows?.reduce(
                        (total, row) => total + parseFloat(row.penaltyAmount),
                        0
                      )}
                    </span>{" "}
                  </div>
                  <div>
                    Total discount :{" "}
                    <span style={{ color: "blue" }}>
                      {filteredRows?.reduce(
                        (total, row) => total + parseFloat(row.discount),
                        0
                      )}
                    </span>{" "}
                  </div>
                </div>
              </div>
              <div
                className="mb-2"
                ref={printState ? componentPDF : null}
                style={{ padding: printState ? "20px" : "0px" }}
              >
                {printState && (
                  <div className="print-div">
                    <div>
                      {/* <Vakilkaro /> */}
                      <h3>Riyms</h3>
                    </div>
                    <div>
                      <p>{COMPANY_NAME}</p>
                    </div>
                  </div>
                )}
                <CTable hover className="" responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col" className="">
                        <span>Sr. No.</span>
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="">
                        <span>Payment Date</span>
                      </CTableHeaderCell>
                      <CTableHeaderCell>
                        Payment Time
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="">
                        <span>Loan Account No.</span>
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="">
                        <span>Customer Name</span>
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="">
                        <span>Mobile Number</span>
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="">
                        <span>Branch Name</span>
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="">
                        <span>Payment Amount</span>
                      </CTableHeaderCell>

                      <CTableHeaderCell scope="col" className="">
                        <span>Payment Status</span>
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {filteredRows?.map((company, index) => {
                      return (
                        <CTableRow key={index}>
                          <CTableDataCell>
                            {indexOfFirstRow + index + 1}
                          </CTableDataCell>
                          <CTableDataCell>
                            {formatDate(company.paymentDate)}
                          </CTableDataCell>
                          <CTableDataCell>
                            {new Date(company.createdAt).toLocaleTimeString("en-US", { hour12: false })}
                          </CTableDataCell>
                          <CTableDataCell>
                            {company.customer.loanAccountNumber
                              ? company.customer.loanAccountNumber
                              : "-"}
                          </CTableDataCell>
                          <CTableDataCell>
                            {`${company.customer.firstName}`}
                          </CTableDataCell>

                          <CTableDataCell>
                            {company.customer.phoneNumber || "-"}
                          </CTableDataCell>
                          <CTableDataCell>
                            {company.branch.branchName || "--"}
                          </CTableDataCell>
                          <CTableDataCell>
                            {company.paymentAmount || "--"}
                          </CTableDataCell>

                          {role === "employee" ? (
                            <CTableDataCell>
                              {company.status ? company.status : "-"}
                            </CTableDataCell>
                          ) : (
                            <CTableDataCell
                              style={{
                                cursor:
                                  company.status === "pending" && "pointer",
                                textTransform: "capitalize",
                              }}
                              onClick={
                                company.status === "pending"
                                  ? () => verifyData(company)
                                  : null
                              }
                            >
                              {company.status ? company.status : "-"}
                            </CTableDataCell>
                          )}
                        </CTableRow>
                      );
                    })}
                  </CTableBody>
                </CTable>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Showing {indexOfFirstRow + 1} to{" "}
                  {Math?.min(indexOfLastRow, emiStatusData?.data?.length)} of{" "}
                  {emiStatusData?.data?.length} entries
                </div>
                <div className="pagination-container">
                  <ReactPaginate
                    activePage={currentPage}
                    itemsCountPerPage={rowsPerPage}
                    totalItemsCount={emiStatusData?.data?.length}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText="Previous"
                    nextPageText="Next"
                    firstPageText="First"
                    lastPageText="Last"
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            </div>
          )}
        </CCardBody>
      </CCard>
    </div>
  );
};

export default PaymentCollection;
