import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-js-pagination";
import { Button } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { getAllCapitals } from "src/redux/actions/customerAction";
import { useNavigate } from "react-router-dom";
import CapitalAddForm from "./operations/capitalAddForm";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "src/services/const";

const DirectorListById = ({
  setDirectorData,
  dataByDirectorId,
  directorData,
}) => {
  const componentPDF = useRef();
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [printState, setPrintState] = useState(false);
  const [listData, setListData] = useState(false);

  const SchemaName = sessionStorage.getItem("schemaName");
  const [currItem, setCurrItem] = useState({});
  const { Capitals } = useSelector((state) => state?.customerReducer);
  useEffect(() => {
    const obj = {
      schemaName: SchemaName,
    };
    dispatch(getAllCapitals(obj));
  }, [showAddForm]);
  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const [showEdit, setShowEdit] = useState(false);

  const handleShowAddForm = (details) => {
    setSelectedCompany(
      Capitals?.data?.map((e) => {
        return e?.loanAmount;
      })
    );
    setShowAddButton(false);
    setShowSelectAndSearch(false);
    setShowAddForm(true);
    setCurrItem(details);
    setShowEdit(false);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const download_btn = () => {
    setPrintState(true);
  };
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });
  const filterdata = Capitals?.data?.filter(
    (item) => item?.directorId === dataByDirectorId?.id
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filterdata?.slice(indexOfFirstRow, indexOfLastRow);
  return (
    <>
      <CRow
      // className="content"
      >
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Fund Details</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol xs={12}>
                  <CCard>
                    <div className="search_bar">
                      {showSelectAndSearch && (
                        <div>
                          <CFormSelect
                            size="sm"
                            className="mb-3"
                            style={{ cursor: "pointer" }}
                            aria-label="Small select example"
                            onChange={(e) => setRowsPerPage(e.target.value)}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                          </CFormSelect>
                        </div>
                      )}

                      <div className="btn_add mt-0 mb-14">
                        {showAddButton && (
                          <Button
                            className="add-btn w-auto    mr-10"
                            onClick={handleShowAddForm}
                          >
                            Add
                          </Button>
                        )}
                        {showAddButton && (
                          <Button
                            className="add-btn  w-auto  mr-10"
                            onClick={download_btn}
                          >
                            Download
                          </Button>
                        )}
                        {showAddButton && (
                          <CSVLink
                            data={currentRows === undefined ? " " : currentRows}
                            filename={"fund-details.csv"}
                          >
                            <CButton className="add-btn w-auto mr-10">
                              CSV
                            </CButton>
                          </CSVLink>
                        )}
                        {showAddButton && (
                          <Button
                            className="add-btn m-0 w-auto"
                            onClick={() => setDirectorData(false)}
                          >
                            Back
                          </Button>
                        )}
                      </div>
                    </div>

                    <CCardBody>
                      <div
                        className={`table-container ${showAccordion ? "hidden" : ""
                          } ${showAddForm ? "hidden" : ""}`}
                      >
                        <div
                          className="mb-2"
                          ref={printState ? componentPDF : null}
                          style={{ padding: printState ? "20px" : "0px" }}
                        >
                          {printState && (
                            <div className="print-div">
                              <div>
                                {/* <Vakilkaro /> */}
                                <h3>Riyms</h3>
                              </div>
                              <div>
                                <p>{COMPANY_NAME}</p>
                              </div>
                            </div>
                          )}

                          <CTable color="dark" hover responsive>
                            <CTableHead className="tabl-head">
                              <CTableRow className="table-row">
                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-1"
                                >
                                  S. No.
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-3"
                                >
                                  Capital
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-3"
                                >
                                  Loan Amount
                                </CTableHeaderCell>

                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-2"
                                >
                                  Loan Date
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-2"
                                >
                                  {`Loan's Interest`}
                                </CTableHeaderCell>
                                <CTableHeaderCell
                                  scope="col"
                                  className="col-md-2"
                                >
                                  Loan Interest Rate
                                </CTableHeaderCell>
                              </CTableRow>
                            </CTableHead>
                            <CTableBody>
                              {currentRows?.map((datas, id) => {
                                const loanDate = new Date(datas?.loanDate); // Loan date
                                const currentDate = new Date(); // Current date

                                const timeInDays = (currentDate - loanDate) / (1000 * 60 * 60 * 24);

                                const daysInYear = loanDate.getFullYear() % 4 === 0 ? 366 : 365; // Check for leap year
                                const timeInYears = timeInDays / daysInYear;

                                const loanInterest = (datas?.loanAmount * datas?.loanInterestRate * timeInYears) / 100;
                                return (
                                  <CTableRow key={id}>
                                    <CTableDataCell>{id + 1}</CTableDataCell>

                                    <CTableDataCell>
                                      {datas?.capital ? datas?.capital : "--"}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {datas?.loanAmount
                                        ? datas?.loanAmount
                                        : "--"}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {datas?.loanDate ? datas?.loanDate : "--"}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {loanInterest !== "--"
                                        ? loanInterest.toFixed(2)
                                        : "--"}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {datas?.loanInterestRate
                                        ? datas?.loanInterestRate
                                        : "--"}
                                    </CTableDataCell>
                                  </CTableRow>
                                );
                              })}
                            </CTableBody>
                          </CTable>
                        </div>

                        <div
                          className="pagin-div"
                        // style={{
                        //   display: "flex",
                        //   justifyContent: "space-between",
                        // }}
                        >
                          <div>
                            Showing {indexOfFirstRow + 1} to{" "}
                            {Math?.min(indexOfLastRow, filterdata?.length)} of{" "}
                            {filterdata?.length} entries
                          </div>
                          <div className="pagination-container">
                            <ReactPaginate
                              activePage={currentPage}
                              itemsCountPerPage={rowsPerPage}
                              totalItemsCount={filterdata?.length}
                              pageRangeDisplayed={5}
                              onChange={handlePageChange}
                              prevPageText="Previous"
                              nextPageText="Next"
                              firstPageText="First"
                              lastPageText="Last"
                              innerClass="pagination"
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          </div>
                        </div>
                      </div>
                      {showAddForm && (
                        <CapitalAddForm
                          selectedCompany={dataByDirectorId?.id}
                          setShowAddForm={setShowAddForm}
                          setListData={setListData}
                          setShowAddButton={setShowAddButton}
                          showAddButton={showAddButton}
                          setShowSelectAndSearch={setShowSelectAndSearch}
                          intial={currItem}
                          edit={showEdit}
                          callBack={() => {
                            window.location.reload();
                            showAddForm(false);
                          }}
                        />
                      )}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <div
            className={`accordion-container ${showAccordion ? "visible" : ""} ${showAddForm ? "visible" : ""
              }`}
          ></div>
        </CCol>
      </CRow>
    </>
  );
};
DirectorListById.propTypes = {
  directorData: PropTypes.bool,
  setDirectorData: PropTypes.func,
  dataByDirectorId: PropTypes.object,
};

export default DirectorListById;
