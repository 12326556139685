import React, { useEffect, useState } from "react";
import { CCardBody, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CBadge, CFormInput, CButton } from "@coreui/react";
import { getDisbursementDetails } from "src/api/allApis";
import { formatDate } from "src/services/helpers";

const DisbursementDetails = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [branchTotals, setBranchTotals] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const role = sessionStorage.getItem("role");
    const branchId = sessionStorage.getItem("branc");
    const schema = sessionStorage.getItem("schemaName");

    useEffect(() => {
        (async () => {
            const response = await getDisbursementDetails({ schemaName: schema });
            const filteredResponseData = role === "company"
                ? response?.data
                : response?.data?.filter(item => item?.branch?.id === Number(branchId));

            setData(filteredResponseData);
            setFilteredData(filteredResponseData);
            const totals = filteredResponseData.reduce((acc, item) => {
                const branchName = item.branch.branchName;
                const amount = Number(item.DisbursementAmount) || 0;
                acc[branchName] = (acc[branchName] || 0) + amount;
                return acc;
            }, {});
            setBranchTotals(totals);
        })();
    }, [role, branchId, schema]);

    const handleFilter = () => {
        const filtered = data.filter(item => {
            const itemDate = new Date(item.createdAt.split("T")[0]);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return (!startDate || itemDate >= start) && (!endDate || itemDate <= end);
        });

        // Update filtered data and branch totals
        setFilteredData(filtered);

        const totals = filtered.reduce((acc, item) => {
            const branchName = item.branch.branchName;
            const amount = Number(item.DisbursementAmount) || 0;
            acc[branchName] = (acc[branchName] || 0) + amount;
            return acc;
        }, {});
        setBranchTotals(totals);
    };

    return (
        <div className="content">
            <div>
                <CCardBody>
                    <h3>Date Filter</h3>
                    <div className="d-flex align-items-center mb-4">
                        <CFormInput
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            placeholder="Start Date"
                            className="me-2"
                        />
                        <CFormInput
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            placeholder="End Date"
                            className="me-2"
                        />
                        <CButton color="primary" onClick={handleFilter}>Apply Filter</CButton>
                    </div>
                    <h3>Branch-wise Total Amount</h3>
                    <CTable color="light" hover responsive>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell>S.No.</CTableHeaderCell>
                                <CTableHeaderCell>Branch Name</CTableHeaderCell>
                                <CTableHeaderCell>Total Amount</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {Object.keys(branchTotals).map((branchName, idx) => (
                                <CTableRow key={idx}>
                                    <CTableDataCell>{idx + 1}</CTableDataCell>
                                    <CTableDataCell>{branchName}</CTableDataCell>
                                    <CTableDataCell>{branchTotals[branchName]}</CTableDataCell>
                                </CTableRow>
                            ))}
                        </CTableBody>
                    </CTable>
                </CCardBody>
            </div>
            <br />
            <CCardBody>
                <CTable color="dark" hover responsive>
                    <CTableHead>
                        <CTableRow>
                            {["S.No.", "Date", "Loan Account No", "Customer Name", "Agent", "Amount", "Branch"].map((header, idx) => (
                                <CTableHeaderCell key={idx}>{header}</CTableHeaderCell>
                            ))}
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {filteredData?.map((item, idx) => (
                            <CTableRow key={idx}>
                                <CTableDataCell>{idx + 1}</CTableDataCell>
                                <CTableDataCell>{formatDate(item.createdAt.split("T")[0])}</CTableDataCell>
                                <CTableDataCell>{item.customer.loanAccountNumber}</CTableDataCell>
                                <CTableDataCell>{item.customer.firstName}</CTableDataCell>
                                <CTableDataCell>{item.branch.branchName}</CTableDataCell>
                                <CTableDataCell>{item.DisbursementAmount}</CTableDataCell>
                                <CTableDataCell>
                                    <CBadge color="info">{item.branch.branchName}</CBadge>
                                </CTableDataCell>
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
            </CCardBody>
        </div>
    );
};

export default DisbursementDetails;
