import React, { useEffect, useState } from "react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { CChart } from "@coreui/react-chartjs";
import { getStyle } from "@coreui/utils";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import SpatialTrackingOutlinedIcon from "@mui/icons-material/SpatialTrackingOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload, } from "@coreui/icons";
import WidgetsBrand from "../../views/widgets/WidgetsBrand";
import WidgetsDropdown from "../../views/widgets/WidgetsDropdown";
import { format } from "date-fns";
import {
  getAllCustomers,
  getAllEmplyeesData,
  getAllGroups,
  getBranches,
  getCollections,
  getCompanyDashboard,
} from "src/api/allApis";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  const [aaddharUsageCount, setaaddharUsageCount] = useState(0);
  const [aaddharTokenBalance, setaaddharTokenBalance] = useState(0);
  const [customerData, setCustomerData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [emiData, setEmiData] = useState([]);
  const schema = sessionStorage.getItem("schemaName");
  const [latestCustomerData, setLatestCustomerData] = useState([]);
  const [customerDatas, setCustomerDatas] = useState();
  const [totalCustomerData, setTotalCustomerDatas] = useState();
  const [monthCounts, setMonthCounts] = useState([]);

  useEffect(() => {
    const companyId = sessionStorage.getItem("companyId");
    fetchCustomers();
    totalDisbursementAmount();
    totalApprovedLoans();
    fetchEMIs();
    fetchStaff();
    fetchAllGroups();
    fetchAllBranches();
    getCompanyDashboard({ companyId: companyId }).then((data) => {
      setaaddharUsageCount(data?.data?.aaddharUsageCount);
      setaaddharTokenBalance(data?.data?.aaddhar_token_limit);
    });
  }, []);

  const fetchCustomers = async () => {
    getAllCustomers({ schemaName: schema })
      .then(users => {
        setCustomerData(users?.data);
        setCustomerDatas(users?.data?.map((ele) => ele?.createdAt));
      })
      .catch((error) => console.log("err", error));
  };
  useEffect(() => {
    if (customerDatas) {
      const formattedDates = customerDatas?.map((dateString) => {
        const date = new Date(dateString);
        return format(date, " MMMM");
      });
      setTotalCustomerDatas(formattedDates);
    }
  }, [customerData]);


  useEffect(() => {
    if (totalCustomerData) {
      const counts = totalCustomerData.reduce((acc, curr) => {
        const month = curr.trim(); // Remove leading space
        acc[month] = (acc[month] || 0) + 1;
        return acc;
      }, {});

      const result = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ].map((month) => counts[month] || 0);

      setMonthCounts(result);
    }
  }, [totalCustomerData]);

  const fetchStaff = () => {
    getAllEmplyeesData({ schemaName: schema })
      .then((users) => setStaffData(users?.data))
      .catch((error) => console.log("err", error));
  };

  const fetchAllGroups = () => {
    getAllGroups({ schemaName: schema })
      .then((users) => setGroupData(users?.data))
      .catch((error) => console.log("err", error));
  };

  const fetchAllBranches = () => {
    getBranches({ schemaName: schema })
      .then((users) => setBranchData(users?.data))
      .catch((error) => console.log("err", error));
  };
  const fetchEMIs = () => {
    getCollections({ schemaName: schema })
      .then((users) => setEmiData(users?.data))
      .catch((error) => console.log("err", error));
  };

  const totalDisbursementAmount = () => {
    let subTotal = 0;
    customerData?.map((item) =>
      item?.disbursements?.map(
        (amt) => (subTotal += Number(amt?.DisbursementAmount))
      )
    );

    return subTotal;
  };
  const totalApprovedLoans = () => {
    let subTotal = 0;
    customerData?.map(
      (item) => (subTotal += Number(item?.loanApprovalStatus === "approved"))
    );

    return subTotal;
  };
  const totalPendingLoans = () => {
    let subTotal = 0;
    customerData?.map(
      (item) => (subTotal += Number(item?.loanApprovalStatus === "pending"))
    );

    return subTotal;
  };
  const totalRejectedLoans = () => {
    let subTotal = 0;
    customerData?.map(
      (item) => (subTotal += Number(item?.loanApprovalStatus === "rejected"))
    );

    return subTotal;
  };

  const progressExample = [
    {
      title: "Total applications",
      value: `${customerData?.length}`,
      percent: 10,
      color: "success",
    },
    {
      title: "Pending Loans",
      value: totalPendingLoans(),
      percent: 20,
      color: "info",
    },
    {
      title: "Approved Loans",
      value: totalApprovedLoans(),
      percent: 60,
      color: "warning",
    },
    {
      title: "Rejected Loans",
      value: totalRejectedLoans(),
      percent: 80,
      color: "danger",
    },
  ];

  useEffect(() => {
    const sortedData = customerData?.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });

    const latestData = sortedData?.slice(0, 10);
    setLatestCustomerData(latestData);
  }, [customerData]);

  return (
    <>
      <div className="content">
        <div
          style={{
            display: "flex",
            gap: "20px",
            paddingLeft: "20px",
            padding: "29px",
          }}
        >
          <Tooltip title="Add Customer">
            <IconButton onClick={() => navigate("/newregisterform")}>
              <PersonAddIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="take Loan">
            <IconButton onClick={() => navigate("/customers")}>
              <CreditScoreIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Products">
            <IconButton onClick={() => navigate("/productlist")}>
              <CategoryOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Group Loan">
            <IconButton onClick={() => navigate("/addgroup")}>
              <Diversity3OutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Track Customer">
            <IconButton onClick={() => navigate("/customer-profile")}>
              <SpatialTrackingOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Banking">
            <IconButton onClick={() => navigate("/banking")}>
              <AccountBalanceOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Bank Statement">
            <IconButton onClick={() => navigate("/bankstatement")}>
              <ReceiptOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>

        <WidgetsDropdown
          usersCount={customerData?.length}
          incomeCount={totalDisbursementAmount()}
          aaddharUsageCount={aaddharUsageCount}
          aaddharTokenBalance={aaddharTokenBalance}
        />
        <CCard className="content mb-4">
          <CCardBody>
            <CRow>
              <CCol sm={5}>
                <h4 id="traffic" className="card-title mb-0">
                  Traffic
                </h4>
                <div className="small text-medium-emphasis">
                  January - December
                </div>
              </CCol>
              <CCol sm={7} className="d-none d-md-block">
                <CButton color="primary" className="float-end">
                  <CIcon icon={cilCloudDownload} />
                </CButton>
                <CButtonGroup className="float-end me-3">
                  {["Day", "Month", "Year"]?.map((value) => (
                    <CButton
                      color="outline-secondary"
                      key={value}
                      className="mx-0"
                      active={value === "Month"}
                    >
                      {value}
                    </CButton>
                  ))}
                </CButtonGroup>
              </CCol>
            </CRow>
            <CChart
              type="bar"
              data={{
                labels: [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ],
                datasets: [
                  {
                    label: "Customer Creations",
                    backgroundColor: "#f87979",
                    data: monthCounts,
                    // [40, 20, 12, 39, 10, 40, 39, 80, 40]
                  },
                ],
              }}
              labels="months"
              options={{
                plugins: {
                  legend: {
                    labels: {
                      color: getStyle("--cui-body-color"),
                    },
                  },
                },
                scales: {
                  x: {
                    grid: {
                      color: getStyle("--cui-border-color-translucent"),
                    },
                    ticks: {
                      color: getStyle("--cui-body-color"),
                    },
                  },
                  y: {
                    grid: {
                      color: getStyle("--cui-border-color-translucent"),
                    },
                    ticks: {
                      color: getStyle("--cui-body-color"),
                    },
                  },
                },
              }}
            />
          </CCardBody>
          <CCardFooter>
            <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
              {progressExample?.map((item, index) => (
                <CCol className="mb-sm-2 mb-0" key={index}>
                  <div className="text-medium-emphasis">{item?.title}</div>
                  <strong>{item?.value}</strong>
                  <CProgress
                    thin
                    className="mt-2"
                    color={item?.color}
                    value={item?.percent}
                  />
                </CCol>
              ))}
            </CRow>
          </CCardFooter>
        </CCard>

        <WidgetsBrand withCharts />

        <CRow>
          <CCol xs>
            <CCard className="mb-4">
              <CCardHeader>Traffic {" & "} Sales</CCardHeader>
              <CCardBody>
                {/* <CRow>
                  <CCol xs={12} md={6} xl={6}>
                    <CRow>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-info py-1 px-3">
                          <div className="text-medium-emphasis small">
                            New Clients
                          </div>
                          <div className="fs-5 fw-semibold">
                            {metaData?.newUsersCount}
                          </div>
                        </div>
                      </CCol>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-danger py-1 px-3 mb-3">
                          <div className="text-medium-emphasis small">
                            Due EMI Collection
                          </div>
                          <div className="fs-5 fw-semibold">
                            {metaData?.dueEMICollection}
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                    <hr className="mt-0" />
                    {progressGroupExample1?.map((item, index) => (
                      <div className="progress-group mb-4" key={index}>
                        <div className="progress-group-prepend">
                          <span className="text-medium-emphasis small">
                            {item.title}
                          </span>
                        </div>
                        <div className="progress-group-bars">
                          <CProgress thin color="info" value={item?.value1} />
                          <CProgress thin color="danger" value={item?.value2} />
                        </div>
                      </div>
                    ))}
                  </CCol>

                  <CCol xs={12} md={6} xl={6}>
                    <CRow>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-warning py-1 px-3 mb-3">
                          <div className="text-medium-emphasis small">
                            {"Today's EMI Collection"}
                          </div>
                          <div className="fs-5 fw-semibold">
                            {metaData?.todayEMICollection}
                          </div>
                        </div>
                      </CCol>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                          <div className="text-medium-emphasis small">
                            Pending
                          </div>
                          <div className="fs-5 fw-semibold">
                            {totalPendingEMIs()}
                          </div>
                        </div>
                      </CCol>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                          <div className="text-medium-emphasis small">
                            Paid EMIs
                          </div>
                          <div className="fs-5 fw-semibold">
                            {totalPaidEMIs()}
                          </div>
                        </div>
                      </CCol>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                          <div className="text-medium-emphasis small">
                            Paid EMIs
                          </div>
                          <div className="fs-5 fw-semibold">
                            {totalPaidEMIs()}
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                    <hr className="mt-0" />

                    {progressGroupExample2?.map((item, index) => (
                      <div className="progress-group mb-4" key={index}>
                        <div className="progress-group-header">
                          <CIcon className="me-2" icon={item?.icon} size="lg" />
                          <span>{item?.title}</span>
                          <span className="ms-auto fw-semibold">
                            {item?.value}%
                          </span>
                        </div>
                        <div className="progress-group-bars">
                          <CProgress thin color="warning" value={item?.value} />
                        </div>
                      </div>
                    ))}

                    <div className="mb-5"></div>

                    {progressGroupExample3?.map((item, index) => (
                      <div className="progress-group" key={index}>
                        <div className="progress-group-header">
                          <CIcon className="me-2" icon={item.icon} size="lg" />
                          <span>{item.title}</span>
                          <span className="ms-auto fw-semibold">
                            {item.value}{" "}
                            <span className="text-medium-emphasis small">
                              ({item.percent}%)
                            </span>
                          </span>
                        </div>
                        <div className="progress-group-bars">
                          <CProgress
                            thin
                            color="success"
                            value={item.percent}
                          />
                        </div>
                      </div>
                    ))}
                  </CCol>
                </CRow> */}
                <CRow md={12}>
                  <CCol md="6">
                    <h5 style={{ marginBottom: "10px" }}>Branches</h5>
                    <CTable
                      align="middle"
                      className="mb-0 border"
                      hover
                      responsive
                    >
                      <CTableHead color="light">
                        <CTableRow>
                          <CTableHeaderCell>SR No</CTableHeaderCell>
                          <CTableHeaderCell>Branch Name</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">
                            Branch Code
                          </CTableHeaderCell>

                          <CTableHeaderCell>Contact Number</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {branchData?.map((item, index) => (
                          <CTableRow v-for="item in tableItems" key={index}>
                            <CTableDataCell>
                              <div>{index + 1}</div>
                            </CTableDataCell>
                            <CTableDataCell>
                              <div>
                                {item?.branchName ? item?.branchName : "--"}
                              </div>
                            </CTableDataCell>

                            <CTableDataCell className="text-center">
                              {item?.branchCode}
                            </CTableDataCell>
                            <CTableDataCell>
                              {item?.contactNumber}
                            </CTableDataCell>
                          </CTableRow>
                        ))}
                      </CTableBody>
                    </CTable>
                  </CCol>
                  <CCol md="6">
                    <h5 style={{ marginBottom: "10px" }}>Groups</h5>
                    <CTable
                      align="middle"
                      className="mb-0 border"
                      hover
                      responsive
                    >
                      <CTableHead color="light">
                        <CTableRow>
                          <CTableHeaderCell>SR No</CTableHeaderCell>
                          <CTableHeaderCell>Group Name</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">
                            Group HeadName
                          </CTableHeaderCell>

                          <CTableHeaderCell>Group Code</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {groupData?.map((item, index) => (
                          <CTableRow v-for="item in tableItems" key={index}>
                            <CTableDataCell>
                              <div>{index + 1}</div>
                            </CTableDataCell>
                            <CTableDataCell>
                              <div>
                                {item?.groupName ? item?.groupName : "--"}
                              </div>
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              {item?.groupHeadName}
                            </CTableDataCell>

                            <CTableDataCell>{item?.groupCode}</CTableDataCell>
                          </CTableRow>
                        ))}
                      </CTableBody>
                    </CTable>
                  </CCol>
                </CRow>
                <br />

                <h5 style={{ marginBottom: "10px" }}>Staff</h5>
                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell>SR No</CTableHeaderCell>
                      <CTableHeaderCell>Name</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        Email
                      </CTableHeaderCell>
                      <CTableHeaderCell>AadharNumber</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        Branch Name
                      </CTableHeaderCell>
                      <CTableHeaderCell>Contact Number</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {staffData?.map((item, index) => (
                      <CTableRow v-for="item in tableItems" key={index}>
                        <CTableDataCell>
                          <div>{index + 1}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{item?.firstName ? item?.firstName : "--"}</div>
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item?.email}
                        </CTableDataCell>
                        <CTableDataCell>{item?.aadharNumber}</CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item?.branch?.branchName}
                        </CTableDataCell>
                        <CTableDataCell>{item?.phoneNumber}</CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
                <br />
                <h5 style={{ marginBottom: "10px" }}>New Customers</h5>
                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell>SR No</CTableHeaderCell>
                      <CTableHeaderCell>Name</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        Email
                      </CTableHeaderCell>
                      <CTableHeaderCell>Loan Amount Requested</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        Aadhar Number
                      </CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        Contact Number
                      </CTableHeaderCell>
                      <CTableHeaderCell>Father Name</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {latestCustomerData?.map((item, index) => (
                      <CTableRow v-for="item in tableItems" key={index}>
                        <CTableDataCell>
                          <div>{index + 1}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{item?.firstName}</div>
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item?.email ? item?.email : "--"}
                        </CTableDataCell>
                        <CTableDataCell>
                          {item?.loanAmountRequested
                            ? item?.loanAmountRequested
                            : "--"}
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item?.aadharNumber ? item?.aadharNumber : ""}
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item?.phoneNumber ? item?.phoneNumber : "--"}
                        </CTableDataCell>
                        <CTableDataCell>
                          {item?.fatherName ? item?.fatherName : "--"}
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </>
  );
};

export default Dashboard;
